import { AxiosResponse } from 'axios';
import IChannelsRespponse from 'src/models/response/IChannelsResponse';
import Instance from './instance';
import IChannelRequest from 'src/models/request/IChannelRequest';
import IChannel from 'src/models/IChannel';
import IEditChannelRequest from 'src/models/request/IEditChannelRequest';

const instance: any = new Instance().getAxiosIns();

class ChannelsAPI {
    public static getChannels(brandId: string, limit: number, offset: number): Promise<AxiosResponse<IChannelsRespponse>> {
        return instance.get(`/brands/${brandId}/channels`, {
            params: {
                limit,
                offset,
            }
        });
    }

    public static getChannel(brandId: string, channelId: string): Promise<AxiosResponse<IChannel>> {
        return instance.get(`/brands/${brandId}/channels/${channelId}`);
    }

    public static createChannel(brandId: string, body: IChannelRequest): Promise<AxiosResponse<any>> {
        return instance.post(`/brands/${brandId}/channels`, body);
    }

    public static editChannel(brandId: string, channelId: string, body: IEditChannelRequest): Promise<AxiosResponse<any>> {
        return instance.patch(`/brands/${brandId}/channels/${channelId}`, body);
    }
}

export default ChannelsAPI;
