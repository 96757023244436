import { CustomInput } from 'src/components/ui/customInput'
import styles from './style.module.scss'
import { MyButton } from 'src/components/ui/myButton'
import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useAppDispatch } from 'src/store';
import ChannelsAPI from 'src/api/ChannelsAPI';
import notificationActions from 'src/store/actions/notificationActions';
import PaginationComponent from 'src/components/ui/PaginationComponent';
import IChannelsRespponse from 'src/models/response/IChannelsResponse';
import IChannel from 'src/models/IChannel';
import { CustomSelect } from 'src/components/ui/customSelect';
import ChatTypesEnum from 'src/models/enums/ChatTypesEnum';

interface IProps {
    tokens: string;
}

const chatTypes = [
    {id: 'whatsapp', name: 'whatsapp'},
    {id: 'whatsgroup', name: 'whatsgroup'},
    {id: 'viber', name: 'viber'},
    {id: 'instagram', name: 'instagram'},
    {id: 'telegram', name: 'telegram'},
    {id: 'vk', name: 'vk'},
    {id: 'avito', name: 'avito'},
    {id: 'telegroup', name: 'telegroup'},
]

const amoEnabled = [
    {id: 'false', name: 'выкл'},
    {id: 'true', name: 'вкл'},
]

const Channels = (props: IProps) => {
    const [name, setName] = useState<string>('');
    const [wazzup_id, setWazzup_id] = useState<string>('');
    const [channels, setChannels] = useState<IChannel[]>([]);
    const [chatType, setChatType] = useState<string>('');
    const [amoIsEnabled, setAmoIsAnabled] = useState<string>('');
    const [bitrixIsEnabled, setBitrixIsAnabled] = useState<string>('');
    const [answer_delay, setAnswer_delay] = useState<string>('');

    const [count, setCount] = useState<number>(0);
    const [page, setPage] = useState<number>(1);
    
    const { brandId } = useParams();

    const dispatch = useAppDispatch();

    const navigate = useNavigate();

    const getChannels = (limit: number, offset: number) => {
        if (brandId) {
            ChannelsAPI.getChannels(brandId, limit, offset)
                .then(response => {
                    if (response.status <= 204) {
                        setChannels(response.data.channels);
                        setCount(response.data.amount);
                    }
                })
        }
    }

    const handleSaveChannel = () => {
        if (brandId) {
            ChannelsAPI.createChannel(brandId, {
                name: name,
                wazzup_id,
                answer_delay: Number(answer_delay),
                amo_is_enabled: amoIsEnabled === 'true' ? true : false, 
                bitrix_is_enabled: bitrixIsEnabled === 'true' ? true : false, 
                chat_type: chatType,
            })
            .then(response => {
                if (response.status <= 204) {
                    setName('');
                    setWazzup_id('');
                    getChannels(10, 0);
                    setPage(1);
                    setAmoIsAnabled('');
                    setAnswer_delay('');
                    setChatType('');
                    dispatch(notificationActions.setMessage({message: 'Успешно'}));
                } else {
                    dispatch(notificationActions.setMessage({message: 'Ошибка'}));
                }
            })
            .catch(() => {
                dispatch(notificationActions.setMessage({message: 'Ошибка'}));
            })
        }
    }

    const handleClick = (id: string) => {
        navigate(`channels/${id}`)
    }

    useEffect(() => {
        if (brandId) {
            getChannels(10, 0);
        }
    }, [])

    return (
        <div className={styles.channelsBlock}>
            <div className={styles.creationBlock}>
                <h4>Добавить канал</h4>
                <div className={styles.inputContainer}>
                    <div className={styles.inputBlock}>
                        <CustomInput
                            value={name}
                            onChange={(e) => setName(e.target.value)}
                            placeholder='Название'
                        />
                        <CustomInput
                            value={wazzup_id}
                            onChange={(e) => setWazzup_id(e.target.value)}
                            placeholder='Wazzup ID'
                        />
                    </div>
                    <div className={styles.inputBlock}>
                        <CustomSelect 
                            options={chatTypes}
                            onChangeMethod={setChatType}
                            selectedId={chatType}
                            placeholder='Тип чата'
                        />
                        <CustomSelect 
                            options={amoEnabled}
                            onChangeMethod={setAmoIsAnabled}
                            selectedId={amoIsEnabled}
                            placeholder='Amo'
                        />
                        <CustomSelect 
                            options={amoEnabled}
                            onChangeMethod={setBitrixIsAnabled}
                            selectedId={bitrixIsEnabled}
                            placeholder='Bitrix'
                        />
                        <CustomInput
                            value={answer_delay}
                            onChange={(e) => setAnswer_delay(e.target.value)}
                            type='number'
                            placeholder='Задержка'
                        />
                    </div>
                    <div className={styles.btn}>
                        <MyButton 
                            disabled={!name || !wazzup_id || !chatType} 
                            handleClickProps={handleSaveChannel}
                        >
                            Сохранить
                        </MyButton>
                    </div>
                </div>
            </div>
            <div className={styles.tokens}>
                Токены: {props.tokens}
            </div>
            <div>
                <table>
                    <thead>
                        <tr>
                            <th>Название</th>
                            <th>ID</th>
                            <th>Задержка</th>
                            <th>AMO</th>
                            <th>Bitrix</th>
                            <th>Тип</th>
                        </tr>
                    </thead>
                    <tbody className={styles['']}>
                        {channels.map((item) => (
                            <tr className={styles.tableTr} key={item.id} onClick={() => handleClick(item.id)}>
                                <td>
                                    <div className={styles.tdTextName}>{item.name}</div>
                                </td>
                                <td>
                                    <div className={styles.tdText}>
                                        {item.id}
                                    </div>
                                </td>
                                <td>
                                    <div className={styles.tdText}>
                                        {item.answer_delay}
                                    </div>
                                </td>
                                <td>
                                    <div className={styles.tdText}>
                                        {item.amo_is_enabled ? 'вкл' : 'выкл'}
                                    </div>
                                </td>
                                <td>
                                    <div className={styles.tdText}>
                                        {item.bitrix_is_enabled ? 'вкл' : 'выкл'}
                                    </div>
                                </td>
                                <td>
                                    <div className={styles.tdText}>
                                        {item.chat_type}
                                    </div>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>

                <PaginationComponent
                    count={count}
                    limit={10}
                    page={page}
                    setPage={setPage}
                    onChange={getChannels}
                />
            </div>
        </div>
    )
}

export default Channels;
