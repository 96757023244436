import styles from './style.module.scss';
import { InputHTMLAttributes, useState } from 'react';
import Eye from '../../../assets/icons/eye-icon.svg'
import ClosedEye from '../../../assets/icons/closed-eye-icon.svg'

interface Props extends InputHTMLAttributes<HTMLInputElement> {
    error?: boolean;
    errorMessage?: string;
    style?: any;
}

export const CustomInput = (props: Props) => {
    const {
        placeholder,
        value = '',
        name,
        onChange,
        disabled,
        error,
        errorMessage,
        type = 'text',
        style,
    } = props;
    
    const [isPasswordType, setIsPasswordType] = useState<boolean>(true);

    return (
        <div className={styles.wrapperInput} style={style}>
            <input
                {...props}
                disabled={disabled}
                className={`${styles.customInput} ${error && styles.error}`}
                placeholder={placeholder}
                value={value}
                name={name}
                type={(type === 'password' && !isPasswordType) ? 'text' : type}
                onChange={onChange}
            />
            {(!!value || value === 0) &&
                <div className={styles.lable}>
                    {placeholder}
                </div>
            }
            {type === 'password' ? (
                <img
                    className={styles['input_eye-img']}
                    src={isPasswordType ? Eye : ClosedEye}
                    alt='password eye'
                    onClick={() => setIsPasswordType(!isPasswordType)}
                />
            ) : null}
            {error && errorMessage &&
                <span className={styles.errorMessage}>{errorMessage}</span>
            }
        </div>
    );
};
