import styles from './style.module.scss';
import { useEffect, useState } from 'react';
import { useAppDispatch } from 'src/store';
import notificationActions from 'src/store/actions/notificationActions';
import BrandsAPI from 'src/api/BrandsAPI';
import { useNavigate, useParams } from 'react-router-dom';
import { CustomInput } from 'src/components/ui/customInput';
import { MyButton } from 'src/components/ui/myButton';
import IBrand from 'src/models/IBrand';
import IEditBrandRequest from 'src/models/request/IEditBrandRequest';
import Channels from './channels';
import Amo from './amo';
import PushesAPI from 'src/api/PushesAPI';
import IPush from 'src/models/IPush';
import Bitrix from './bitrix';
import { CustomSelect } from 'src/components/ui/customSelect';
import { hours, minutes } from 'src/utils/constants/timeConstant';

export interface IAmoData {
    amo_client_id: string,
    amo_subdomain: string,
    amo_client_secret: string,
    amo_user_id: string,
}

export interface IBitrixData {
    bitrix_subdomain: string,
    bitrix_webhook_url: string,
}

const BrandPage = () => {
    const [currentBrand, setCurrentBrand] = useState<IBrand | null>(null);
    const [name, setName] = useState<string>('');
    const [assistant_id, setAssistant_id] = useState<string>('');
    const [wazzup_api_key, setWazzup_api_key] = useState<string>('');
    const [openai_api_key, setOpenai_api_key] = useState<string>('');
    const [yclients_api_key, setYclients_api_key] = useState<string>('');
    const [yclients_company_id, setYclients_company_id] = useState<string>('');
    const [tokens, setTokens] = useState<string>('');
    const [isEditing, setIsEditing] = useState<boolean>(false);
    const [interval, setInterval] = useState<string>('');
    const [intervals, setIntervals] = useState<IPush[]>([]);
    const [isAddInterval, setIsAddInterval] = useState<boolean>(false);
    const [amoData, setAmoData] = useState<IAmoData | null>(null);
    const [bitrixData, setBitrixData] = useState<IBitrixData | null>(null);

    const [hoursStart, setHoursStart] = useState<string>('');
    const [hoursEnd, setHoursEnd] = useState<string>('');
    const [minutesStart, setMinutesStart] = useState<string>('');
    const [minutesEnd, setMinutesEnd] = useState<string>('');

    const { brandId } = useParams();

    const dispatch = useAppDispatch();

    const navigate = useNavigate();

    const compliteTime = (hh: string, mm: string) => {
        if (hh && mm) {
            return hh + ':' + mm + ':00'
        } else {
            return null
        }
    }

    const getIntervals = () => {
        if (brandId) {
            PushesAPI.getPushes(brandId)
                .then(response => {
                    if (response.status <= 204) {
                        setIntervals(response.data)
                    } else {
                        dispatch(notificationActions.setMessage({message: 'Ошибка'}));
                    }
                })
                .catch(() => {
                    dispatch(notificationActions.setMessage({message: 'Ошибка'}));
                })
        }
    }

    const getBrand = (brandId: string) => {
        BrandsAPI.getBrand(brandId)
            .then(response => {
                if (response.status <= 204) {
                    setCurrentBrand(response.data.brand);
                    setName(response.data.brand.name);
                    setAssistant_id(response.data.brand.assistant_id);
                    setWazzup_api_key(response.data.brand.wazzup_api_key);
                    setOpenai_api_key(response.data.brand.openai_api_key);
                    setYclients_api_key(response.data.brand.yclients_api_key);
                    setYclients_company_id(response.data.brand.yclients_company_id);
                    setTokens(`${response.data.in_tokens} / ${response.data.out_tokens}`)

                    setAmoData({
                        amo_client_id: response.data.brand.amo_client_id || '',
                        amo_client_secret: response.data.brand.amo_client_secret || '',
                        amo_subdomain: response.data.brand.amo_subdomain || '',
                        amo_user_id: response.data.brand.amo_user_id || '',
                    })

                    setBitrixData({
                        bitrix_subdomain: response.data.brand.bitrix_subdomain || '',
                        bitrix_webhook_url: response.data.brand.bitrix_webhook_url || '',
                    })
                    
                    if (response.data.brand.working_hours_start && response.data.brand.working_hours_end) {
                        setHoursStart(response.data.brand.working_hours_start.split(':')[0]);
                        setHoursEnd(response.data.brand.working_hours_end.split(':')[0]);
                        setMinutesStart(response.data.brand.working_hours_start.split(':')[1]);
                        setMinutesEnd(response.data.brand.working_hours_end.split(':')[1]);
                    }
                } else {
                    dispatch(notificationActions.setMessage({message: 'Ошибка'}));
                }
            })
            .catch(() => {
                dispatch(notificationActions.setMessage({message: 'Ошибка'}));
            })
    }

    const createBrand = () => {
        BrandsAPI.createBrand(
            {
                name,
                assistant_id,
                wazzup_api_key,
                openai_api_key,
                yclients_api_key,
                yclients_company_id,
                working_hours_start: compliteTime(hoursStart, minutesStart),
                working_hours_end: compliteTime(hoursEnd, minutesEnd),
            }
        )
        .then(response => {
            if (response.status <= 204) {
                dispatch(notificationActions.setMessage({message: 'Успешно'}));
                navigate(`brand/${response.data.id}`);
            } else {
                dispatch(notificationActions.setMessage({message: 'Ошибка'}));
            }
        })
        .catch(() => {
            dispatch(notificationActions.setMessage({message: 'Ошибка'}));
        })
    }

    const editBrand = () => {
        if (brandId) {
            const data: IEditBrandRequest = {};
    
            currentBrand?.name !== name ? data.name = name : null;
            currentBrand?.assistant_id !== assistant_id ? data.assistant_id = assistant_id : null;
            currentBrand?.wazzup_api_key !== wazzup_api_key ? data.wazzup_api_key = wazzup_api_key : null;
            currentBrand?.openai_api_key !== openai_api_key ? data.openai_api_key = openai_api_key : null;
            currentBrand?.yclients_api_key !== yclients_api_key ? data.yclients_api_key = yclients_api_key : null;
            currentBrand?.yclients_company_id !== yclients_company_id ? data.yclients_company_id = yclients_company_id : null;
            if (
                (
                    currentBrand?.working_hours_start !== compliteTime(hoursStart, minutesStart) ||
                    currentBrand?.working_hours_end !== compliteTime(hoursEnd, minutesEnd)
                ) && (
                    compliteTime(hoursStart, minutesStart) && compliteTime(hoursEnd, minutesEnd)
                )
            ) {
                data.working_hours_start = compliteTime(hoursStart, minutesStart);
                data.working_hours_end = compliteTime(hoursEnd, minutesEnd);
            }
        
            BrandsAPI.editBrand(brandId, data)
                .then(response => {
                    if (response.status <= 204) {
                        setIsEditing(false);
                        getBrand(brandId)
                        dispatch(notificationActions.setMessage({message: 'Успешно'}));
                    } else {
                        dispatch(notificationActions.setMessage({message: 'Ошибка'}));
                    }
                })
                .catch(() => {
                    dispatch(notificationActions.setMessage({message: 'Ошибка'}));
                })
        }
    }

    const handleSave = () => {
        if (brandId) {
            editBrand();
        } else {
            createBrand()
        }
    }

    const handleAddInterval = () => {
        if (brandId) {
            PushesAPI.createPushes(brandId, Number(interval))
                .then(response => {
                    if (response.status <= 204) {
                        setInterval('');
                        setIsAddInterval(false);
                        getIntervals();
                        dispatch(notificationActions.setMessage({message: 'Успешно'}));
                    } else {
                        dispatch(notificationActions.setMessage({message: 'Ошибка'}));
                    }
                })
                .catch(() => {
                    dispatch(notificationActions.setMessage({message: 'Ошибка'}));
                })
        }
    }

    const handleDeleteInterval = (pushId: string) => {
        if (brandId) {
            PushesAPI.deletePushes(brandId, pushId)
                .then(response => {
                    if (response.status <= 204) {
                        getIntervals();
                        dispatch(notificationActions.setMessage({message: 'Успешно'}));
                    } else {
                        dispatch(notificationActions.setMessage({message: 'Ошибка'}));
                    }
                })
                .catch(() => {
                    dispatch(notificationActions.setMessage({message: 'Ошибка'}));
                })
        }
    }

    useEffect(() => {
        if (brandId) {
            getBrand(brandId);
            getIntervals();
        }
    }, [])

    return (
        <div className={styles.body}>
            <div className={styles.inputBlock}>
                <h3>{brandId ? '' : 'Создание'}</h3>
                <CustomInput
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                    placeholder='Название'
                    disabled={!!brandId && !isEditing}
                />
                <CustomInput
                    value={assistant_id}
                    onChange={(e) => setAssistant_id(e.target.value)}
                    placeholder='Assistant ID'
                    disabled={!!brandId && !isEditing}
                />
                <CustomInput
                    value={wazzup_api_key}
                    onChange={(e) => setWazzup_api_key(e.target.value)}
                    placeholder='Wazzup API key'
                    disabled={!!brandId && !isEditing}
                />
                <CustomInput
                    value={openai_api_key}
                    onChange={(e) => setOpenai_api_key(e.target.value)}
                    placeholder='Openai API key'
                    disabled={!!brandId && !isEditing}
                />
                <CustomInput
                    value={yclients_api_key}
                    onChange={(e) => setYclients_api_key(e.target.value)}
                    placeholder='Yclients API key'
                    disabled={!!brandId && !isEditing}
                />
                <CustomInput
                    value={yclients_company_id}
                    onChange={(e) => setYclients_company_id(e.target.value)}
                    placeholder='Yclients Company ID'
                    disabled={!!brandId && !isEditing}
                />
                <div className={styles.timeBlock}>
                    Время работы
                    <div className={styles.timeItem}>
                        с
                        <div className={styles.inputTime} style={{marginLeft: '5px'}}>
                            <CustomSelect
                                selectedId={hoursStart}
                                onChangeMethod={setHoursStart}
                                options={hours}
                                disabled={!!brandId && !isEditing}
                            />
                        </div>
                        :
                        <div className={styles.inputTime}>
                            <CustomSelect
                                selectedId={minutesStart}
                                onChangeMethod={setMinutesStart}
                                options={minutes}
                                disabled={!!brandId && !isEditing}
                            />
                        </div>
                    </div>
                    <div className={styles.timeItem}>
                        до
                        <div className={styles.inputTime} style={{marginLeft: '-2px'}}>
                            <CustomSelect
                                selectedId={hoursEnd}
                                onChangeMethod={setHoursEnd}
                                options={hours}
                                disabled={!!brandId && !isEditing}
                            />
                        </div>
                        :
                        <div className={styles.inputTime}>
                            <CustomSelect
                                selectedId={minutesEnd}
                                onChangeMethod={setMinutesEnd}
                                options={minutes}
                                disabled={!!brandId && !isEditing}
                            />
                        </div>
                    </div>
                </div>
                {
                    !!brandId && !isEditing ? (
                        <MyButton 
                            handleClickProps={() => setIsEditing(true)}
                        >
                            Изменить
                        </MyButton>
                    ): (
                        <MyButton 
                            disabled={!brandId && (!name || !assistant_id || !wazzup_api_key || !openai_api_key)} 
                            handleClickProps={handleSave}
                        >
                            Сохранить
                        </MyButton>
                    )
                }
                {!!brandId &&
                    <>
                        <div className={styles.titleIntervals}>
                            Интервалы дожимных сообщений (минуты)
                        </div>
                        <div className={styles.intervalsBlock}>
                            {
                                intervals.map(it => (
                                    <div className={styles.interval}>
                                        <CustomInput
                                            value={it.interval}
                                            placeholder=''
                                        />
                                        <MyButton 
                                            handleClickProps={() => handleDeleteInterval(it.id)}
                                            className={styles.deleteBtn}
                                        >
                                            Удалить
                                        </MyButton>
                                    </div>
                                ))
                            }
                            {isAddInterval &&
                                <div className={styles.interval}>
                                    <CustomInput
                                        value={interval}
                                        type='number'
                                        onChange={(e) => setInterval(e.target.value)}
                                        placeholder=''
                                    />
                                    <MyButton 
                                        disabled={!interval} 
                                        handleClickProps={handleAddInterval}
                                    >
                                        Сохранить
                                    </MyButton>
                                </div>
                            }
                            {!isAddInterval &&
                                <div>
                                    <MyButton
                                        handleClickProps={() => setIsAddInterval(true)}
                                    >
                                        Добавить
                                    </MyButton>
                                </div>
                            }
                        </div>
                    </>
                }
            </div>
            {!!brandId &&
                <div className={styles.rightBlock}>
                    {amoData && <Amo amoData={amoData} />}
                    {bitrixData && <Bitrix bitrixData={bitrixData} />}
                    <Channels tokens={tokens}/>
                </div>
            }
        </div>
    )
}

export default BrandPage;
