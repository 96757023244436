import { CustomInput } from 'src/components/ui/customInput'
import styles from './style.module.scss'
import { MyButton } from 'src/components/ui/myButton'
import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useAppDispatch } from 'src/store';
import notificationActions from 'src/store/actions/notificationActions';
import { IBitrixData } from '..';
import BrandsAPI from 'src/api/BrandsAPI';

interface IProps {
    bitrixData: IBitrixData;
}

const Bitrix = ({bitrixData}: IProps) => {
    const [bitrix_subdomain, setBitrix_subdomain] = useState<string>(bitrixData.bitrix_subdomain);
    const [bitrix_webhook_url, setBitrix_webhook_url] = useState<string>(bitrixData.bitrix_webhook_url);
    
    const { brandId } = useParams();

    const dispatch = useAppDispatch();

    const handleSaveBitrix = () => {
        if (brandId) {
            BrandsAPI.createBrandBitrix(brandId, {
                bitrix_subdomain,
                bitrix_webhook_url,
            })
            .then(response => {
                if (response.status <= 204) {
                    dispatch(notificationActions.setMessage({message: 'Успешно'}));
                    window.location.reload();
                } else {
                    dispatch(notificationActions.setMessage({message: 'Ошибка'}));
                }
            })
            .catch(() => {
                dispatch(notificationActions.setMessage({message: 'Ошибка'}));
            })
        }
    }

    return (
        <div className={styles.channelsBlock}>
            <div className={styles.creationBlock}>
                <h4>Bitrix</h4>
                <div className={styles.inputContainer}>
                    <div className={styles.inputBlock}>
                        <CustomInput
                            value={bitrix_subdomain}
                            onChange={(e) => setBitrix_subdomain(e.target.value)}
                            placeholder='Домен пользователя'
                        />
                        <CustomInput
                            value={bitrix_webhook_url}
                            onChange={(e) => setBitrix_webhook_url(e.target.value)}
                            placeholder='Вебхук'
                        />
                        <MyButton 
                            disabled={!bitrix_subdomain || !bitrix_webhook_url} 
                            handleClickProps={handleSaveBitrix}
                        >
                            Сохранить
                        </MyButton>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Bitrix;
