import styles from './style.module.scss';
import { useDispatch } from 'react-redux';
import { useState } from 'react';
import { CustomInput } from 'src/components/ui/customInput';
import { MyButton } from 'src/components/ui/myButton';
import AuthAPI from 'src/api/AuthAPI';
import { useNavigate } from 'react-router-dom';
import notificationActions from 'src/store/actions/notificationActions';

const Registaration = () => {
    const [isAgree, setIsAgree] = useState<boolean>(false);
    const [isOpenConfirmEmail, setIsOpenConfirmEmail] = useState<boolean>(false);
    const [isOpenResendEmail, setIsOpenResendEmail] = useState<boolean>(false);

    const [name, setName] = useState<string>('');
    const [isErrorName, setIsErrorName] = useState<boolean>(false);
    const [surname, setSurname] = useState<string>('');
    const [patronymic, setPatronymic] = useState<string>('');

    const [email, setEmail] = useState<string>('');
    const [isErrorEmail, setIsErrorEmail] = useState<boolean>(false);

    const [password, setPassword] = useState<string>('');
    const [isErrorPassword, setIsErrorPassword] = useState<boolean>(false);

    const [confirmPassword, setConfirmPassword] = useState<string>('');
    const [isErrorConfirmPassword, setIsErrorConfirmPassword] = useState<boolean>(false);

    const [phone, setPhone] = useState<string>('');
    const [isErrorPhone, setIsErrorPhone] = useState<boolean>(false);

    const [isErrorAgreement, setIsErrorAgreement] = useState<boolean>(false);

    const dispatch = useDispatch();
    const navigate = useNavigate();

    const checkNameField = (name: string) => {
        if (!name) {
            return false;
        }

        return true;
    };

    const checkEmailReady = (email: string) => {
        const emailRegex = /@../;
        return emailRegex.test(email);
    };

    const checkPasswordReady = (password: string) => {
        // const passwordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[^\w\s]).{8,}/;
        const passwordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*()\-_=+]).{8,}$/;
        return passwordRegex.test(password);
    };

    const checkPasswordSecond = (password: string, confirmPassword: string) => {
        return confirmPassword !== password;
    };

    const checkPhone = (phone: string) => {
        const phoneRegex = /^((\+)[\- ]?)?(\(?\d{3}\)?[\- ]?)?[\d\- ]{8,11}$/;
        // const phoneRegex = /^(\s*)?(\+)?([- _():=+]?\d[- _():=+]?){10,14}(\s*)?$/;
        return phoneRegex.test(phone);
    };

    const handleNameChange = (val: string) => {
        setName(val);
        setIsErrorName(false);
    };

    const handleSurnameChange = (val: string) => {
        setSurname(val);
    };

    const handlePatronymicChange = (val: string) => {
        setPatronymic(val);
    };

    const handleEmailChange = (val: string) => {
        const reg = /\s/;

        if (reg.test(val)) {
            setEmail(email);
        } else {
            setEmail(val);
        }
        setIsErrorEmail(false);
    };

    const handlePasswordChange = (val: string) => {
        const reg = /\s/;

        if (reg.test(val)) {
            setPassword(password);
        } else {
            setPassword(val);
        }
        setIsErrorPassword(false);
    };

    const handleConfirmPasswordChange = (val: string) => {
        const reg = /\s/;

        if (reg.test(val)) {
            setConfirmPassword(confirmPassword);
        } else {
            setConfirmPassword(val);
        }
        setIsErrorConfirmPassword(false);
    };

    const handlePhoneChange = (val: string) => {
        const regex = /[a-z]|[A-Z]|[а-я]|[А-Я]/;

        if (!regex.test(val)) {
            setPhone(val);
        } else {
            setPhone(phone);
        }
        setIsErrorPhone(false);
    };

    const checkValidationReady = () => {
        const resArray: boolean[] = [];

        if (!checkNameField(email)) {
            setIsErrorName(true);
            resArray.push(true);
        }

        if (!checkEmailReady(email)) {
            setIsErrorEmail(true);
            resArray.push(true);
        }

        if (!checkPasswordReady(password)) {
            setIsErrorPassword(true);
            resArray.push(true);
        }

        if (checkPasswordSecond(password, confirmPassword) || !confirmPassword) {
            setIsErrorConfirmPassword(true);
            resArray.push(true);
        }

        if (!checkPhone(phone)) {
            setIsErrorPhone(true);
            resArray.push(true);
        }

        if (resArray.length > 0) return false;
        else return true;
    };

    const sendRequest = () => {
        const data = {
            email,
            password,
            name,
            surname,
            patronymic,
            phone,
        }

        AuthAPI.register(data)
            .then(response => {
                if (response.status <= 204) {
                    dispatch(notificationActions.setMessage({message: 'Успешно'}));
                    navigate('/auth');
                } else {
                    dispatch(notificationActions.setMessage({message: typeof(response.data.detail) === 'string' ? response.data.detail :'Ошибка'}));
                }
            })
            .catch(err => {
                dispatch(notificationActions.setMessage({message: typeof(err.response.data.detail) === 'string' ? err.response.data.detail :'Ошибка'}));
            })
    };

    const handleSubmit = () => {
        if (checkValidationReady()) {
            sendRequest();
        }
    };


    return (
        <div className={styles.wrapperRegister}>
            <div key='login' className={styles.formRegister}>
                <span className={styles.title}>Регистрация</span>
                <CustomInput
                    placeholder='Имя'
                    value={name}
                    onChange={(e) => handleNameChange(e.target.value)}
                    name='name'
                    error={isErrorName}
                    errorMessage={'Некорректно заполнено поле "Имя"'}
                />

                <CustomInput
                    placeholder='Фамилия'
                    value={surname}
                    onChange={(e) => handleSurnameChange(e.target.value)}
                    name='surname'
                />

                <CustomInput
                    placeholder='Отчество'
                    value={patronymic}
                    onChange={(e) => handlePatronymicChange(e.target.value)}
                    name='patronymic'
                />

                <CustomInput
                    placeholder='Электронная почта'
                    value={email}
                    onChange={(e) => handleEmailChange(e.target.value)}
                    name='email'
                    error={isErrorEmail}
                    errorMessage={'Некорректно заполнено поле "Email"'}
                />

                <CustomInput
                    placeholder='Номер телефона'
                    value={phone}
                    onChange={(e) => handlePhoneChange(e.target.value)}
                    name='phone'
                    error={isErrorPhone}
                    errorMessage={'Некорректно заполнено поле "Номер телефона"'}
                />

                <CustomInput
                    placeholder='Пароль'
                    type='password'
                    value={password}
                    onChange={(e) => handlePasswordChange(e.target.value)}
                    name='password'
                    error={isErrorPassword}
                    errorMessage={`Пароль должен включать 
                        8 символов с наличием заглавной, строчной букв, цифр и спецсимволов`}
                />

                <CustomInput
                    placeholder='Подтверждение пароля'
                    type='password'
                    value={confirmPassword}
                    onChange={(e) => handleConfirmPasswordChange(e.target.value)}
                    name='password2'
                    error={isErrorConfirmPassword}
                    errorMessage='Пароли не совпадают'
                />

                <MyButton
                    children='Зарегистрироваться'
                    handleClickProps={handleSubmit}
                    className={styles.authBtn}
                />

                <div className={styles.underText}>
                    <span className={styles.oneText}>Есть аккаунт?</span>
                    <div onClick={() => (window.location.pathname = '/auth')}>
                        <span className={styles.twoText}>Войти</span>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Registaration
