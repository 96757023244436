import { CustomInput } from 'src/components/ui/customInput'
import styles from './style.module.scss'
import { MyButton } from 'src/components/ui/myButton'
import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useAppDispatch } from 'src/store';
import notificationActions from 'src/store/actions/notificationActions';
import { IAmoData } from '..';
import BrandsAPI from 'src/api/BrandsAPI';

interface IProps {
    amoData: IAmoData;
}

const Amo = ({amoData}: IProps) => {
    const [amo_client_id, setAmo_client_id] = useState<string>(amoData.amo_client_id);
    const [amo_client_secret, setAmo_client_secret] = useState<string>(amoData.amo_client_secret);
    const [amo_subdomain, setAmo_subdomain] = useState<string>(amoData.amo_subdomain);
    const [amo_user_id, setAmo_user_id] = useState<string>(amoData.amo_user_id);
    const [amo_code, setAmo_code] = useState<string>('');
    const [isCreate, setIsCreate] = useState<boolean>(amoData.amo_user_id ? false : true)
    
    const { brandId } = useParams();

    const dispatch = useAppDispatch();

    const handleSaveChannel = () => {
        if (brandId) {
            BrandsAPI.createBrandAmo(brandId, {
                amo_client_id,
                amo_client_secret,
                amo_code,
                amo_subdomain,
                amo_user_id,
            })
            .then(response => {
                if (response.status <= 204) {
                    dispatch(notificationActions.setMessage({message: 'Успешно'}));
                    window.location.reload();
                } else {
                    dispatch(notificationActions.setMessage({message: 'Ошибка'}));
                }
            })
            .catch(() => {
                dispatch(notificationActions.setMessage({message: 'Ошибка'}));
            })
        }
    }

    const copyLink = () => {
        const input: any = document.createElement('input');
	    input.id = 'temp';
	    input.style.height = 0;
	    document.body.appendChild(input);
	    input.value = `https://ai.chat.test-loyalty.ru/api/webhook/amo/${brandId}`;
	    const selector: any = document.querySelector('#temp');
	    selector.select();
	    document.execCommand('copy');
	    document.body.removeChild(input);

    }

    return (
        <div className={styles.channelsBlock}>
            <div className={styles.creationBlock}>
                <h4>AMO</h4>
                <div className={styles.inputContainer}>
                    <div className={styles.inputBlock}>
                        <CustomInput
                            value={amo_client_id}
                            onChange={(e) => setAmo_client_id(e.target.value)}
                            placeholder='ID интеграции'
                        />
                        <CustomInput
                            value={amo_client_secret}
                            onChange={(e) => setAmo_client_secret(e.target.value)}
                            placeholder='Ключ интеграции'
                        />
                    </div>
                    <div className={styles.inputBlock}>
                        <CustomInput
                            value={amo_subdomain}
                            onChange={(e) => setAmo_subdomain(e.target.value)}
                            placeholder='Домен пользователя'
                        />
                        <CustomInput
                            value={amo_user_id}
                            onChange={(e) => setAmo_user_id(e.target.value)}
                            placeholder='ID пользователя'
                        />
                        {isCreate &&
                            <CustomInput
                                value={amo_code}
                                onChange={(e) => setAmo_code(e.target.value)}
                                placeholder='Одноразовый код'
                            />
                        }
                    </div>
                    {isCreate &&
                        <div className={styles.btn}>
                            <MyButton 
                                handleClickProps={copyLink}
                            >
                                Копировать ссылку
                            </MyButton>
                            <MyButton 
                                disabled={!amo_client_id || !amo_client_secret || !amo_subdomain || !amo_code} 
                                handleClickProps={handleSaveChannel}
                            >
                                Сохранить
                            </MyButton>
                        </div>
                    }
                </div>
            </div>
        </div>
    )
}

export default Amo;
