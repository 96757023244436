export const hours = [
    {id: '01', name: '01'},
    {id: '02', name: '02'},
    {id: '03', name: '03'},
    {id: '04', name: '04'},
    {id: '05', name: '05'},
    {id: '06', name: '06'},
    {id: '07', name: '07'},
    {id: '08', name: '08'},
    {id: '09', name: '09'},
    {id: '10', name: '10'},
    {id: '11', name: '11'},
    {id: '12', name: '12'},
    {id: '13', name: '13'},
    {id: '14', name: '14'},
    {id: '15', name: '15'},
    {id: '16', name: '16'},
    {id: '17', name: '17'},
    {id: '18', name: '18'},
    {id: '19', name: '19'},
    {id: '20', name: '20'},
    {id: '21', name: '21'},
    {id: '22', name: '22'},
    {id: '23', name: '23'},
]

export const minutes = [
    {id: '00', name: '00'},
    {id: '05', name: '05'},
    {id: '10', name: '10'},
    {id: '15', name: '15'},
    {id: '20', name: '20'},
    {id: '25', name: '25'},
    {id: '30', name: '30'},
    {id: '35', name: '35'},
    {id: '40', name: '40'},
    {id: '45', name: '45'},
    {id: '50', name: '50'},
    {id: '55', name: '55'},
]