import styles from './style.module.scss';
import { useEffect, useState } from 'react';
import { useAppDispatch } from 'src/store';
import notificationActions from 'src/store/actions/notificationActions';
import { useNavigate, useParams } from 'react-router-dom';
import { CustomInput } from 'src/components/ui/customInput';
import { MyButton } from 'src/components/ui/myButton';
import DialoguesAPI from 'src/api/DialoguesAPI';
import IDialog from 'src/models/IDialog';
import moment from 'moment';

const DialoguePage = () => {

    const [dialog, setDialog] = useState<IDialog | null>(null);
    const [isOpen, setIsOpen] = useState<boolean>(false);

    const { brandId, channelId, dialogueId } = useParams();

    const dispatch = useAppDispatch();

    const navigate = useNavigate();

    const getDialogue = () => {
        if (brandId && channelId && dialogueId) {
            DialoguesAPI.getDialogue(brandId, channelId, dialogueId)
                .then(response => {
                    if (response.status <= 204) {
                        setDialog(response.data)
                    } else {
                        dispatch(notificationActions.setMessage({message: 'Ошибка'}));
                    }
                })
                .catch(() => {
                    dispatch(notificationActions.setMessage({message: 'Ошибка'}));
                })
        }
    }
    
    const handleTogle = () => {
        if (brandId && channelId && dialogueId) {
            DialoguesAPI.editDialog(brandId, channelId, dialogueId, !dialog?.is_active)
                .then(response => {
                    if (response.status <= 204) {
                        getDialogue();
                        dispatch(notificationActions.setMessage({message: 'Успешно'}));
                    } else {
                        dispatch(notificationActions.setMessage({message: 'Ошибка'}));
                    }
                })
                .catch(() => {
                    dispatch(notificationActions.setMessage({message: 'Ошибка'}));
                })
        }
    }

    const deleteDialog = () => {
        if (brandId && channelId && dialogueId) {
            DialoguesAPI.deleteDialog(brandId, channelId, dialogueId)
                .then(response => {
                    if (response.status <= 204) {
                        dispatch(notificationActions.setMessage({message: 'Успешно'}));
                        navigate(`/brand/${brandId}/channels/${channelId}`);
                    } else {
                        dispatch(notificationActions.setMessage({message: 'Ошибка'}));
                    }
                })
                .catch(() => {
                    dispatch(notificationActions.setMessage({message: 'Ошибка'}));
                })
        }
    }

    const handleDelete = () => {
        setIsOpen(true);
    }

    useEffect(() => {
        getDialogue();
    }, [])

    return (
        <div className={styles.body}>
            <div className={styles.inputBlock}>
                <h3>Диалог</h3>
                <CustomInput
                    value={dialog?.thread_id}
                    placeholder='ID треда'
                />
                <CustomInput
                    value={moment(dialog?.last_contact).format('DD-MM-YYYY hh:mm:ss')}
                    placeholder='Дата и время последнего сообщения'
                />
                <CustomInput
                    value={dialog?.contact_name || ' '}
                    placeholder='Имя клиента'
                />
                <CustomInput
                    value={dialog?.contact_username || ' '}
                    placeholder='Никнейм клиента'
                />
                <CustomInput
                    value={dialog?.contact_phone || ' '}
                    placeholder='Телефон клиента'
                />
                <CustomInput
                    value={dialog?.is_active ? 'Вкл' : 'Выкл'}
                    placeholder='Ассистент'
                />
                {
                    <MyButton  
                        handleClickProps={handleTogle}
                    >
                        {dialog?.is_active ? 'Выключить' : 'Включить'}
                    </MyButton> 
                }
            </div>
            <div className={styles.tableBlock}>
                <MyButton  
                    handleClickProps={handleDelete}
                    className={styles.deleteBtn}
                >
                    Удалить
                </MyButton> 
                <div>
                    <table>
                        <thead>
                            <tr>
                                <th className={styles.first}>Вопрос</th>
                                <th>Ответ</th>
                            </tr>
                        </thead>
                        <tbody className={styles['']}>
                            {dialog?.dialogue_data.map((item) => (
                                <tr key={item.id}>
                                    <td className={styles.first}>
                                        <div className={styles.tdTextName}>{item.question}</div>
                                    </td>
                                    <td>
                                        <div className={styles.tdTextName}>{item.data}</div>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
            </div>
            {isOpen &&
                <div className={styles.overlay}>
                    <div className={styles.modal}>
                        <div className={styles.title}>Удалить диалог?</div>
                        <div className={styles.btnContainer}>
                            <MyButton  
                                handleClickProps={() => setIsOpen(false)}
                            >
                                Отмена
                            </MyButton> 
                            <MyButton  
                                handleClickProps={deleteDialog}
                            >
                                Удалить
                            </MyButton> 
                        </div>
                    </div>
                </div>
            }
        </div>
    )
}

export default DialoguePage;
