import style from './style.module.scss';
import React, { useEffect, useRef, useState } from 'react';
import { AxiosResponse } from 'axios';
import useDebounce from 'src/utils/useDebounce';
import { SearchIcon } from 'src/assets/icons/icons';
import { CustomInput } from '../customInput';

interface Props {
    onChangeMethod: (value: any) => void;
    placeholder?: string;
    title?: string;
    name?: string;
}

export const CustomSearch = (props: Props) => {
    const {
        onChangeMethod,
        placeholder,
        name,
    } = props;

    const [value, setValue] = useState<string>('');

    const debonceValue = useDebounce(value, 800);

    const handleChange = (event: any) => {
        setValue(event.target.value);
    };

    useEffect(() => {
        onChangeMethod(debonceValue);
    }, [debonceValue]);

    return (
        <div className={style.wrapperCustomSelect}>
            <CustomInput
                name={name}
                value={value}
                placeholder={placeholder}
                onInput={handleChange}
            />
            <div className={style.icon}>
                <SearchIcon />
            </div>
        </div>
    );
};
