import { CustomInput } from 'src/components/ui/customInput';
import styles from './style.module.scss';
import { MyButton } from 'src/components/ui/myButton';
import { useEffect, useState } from 'react';
import IDialog from 'src/models/IDialog';
import PaginationComponent from 'src/components/ui/PaginationComponent';
import DialoguesAPI from 'src/api/DialoguesAPI';
import { useAppDispatch } from 'src/store';
import notificationActions from 'src/store/actions/notificationActions';
import { useNavigate, useParams } from 'react-router-dom';
import moment from 'moment';
import ChannelsAPI from 'src/api/ChannelsAPI';
import { CustomSelect } from 'src/components/ui/customSelect';
import FilePicker from 'src/components/ui/filePicker';
import { CustomSearch } from 'src/components/ui/customSearch';

const chatTypes = [
    {id: 'whatsapp', name: 'whatsapp'},
    {id: 'whatsgroup', name: 'whatsgroup'},
    {id: 'viber', name: 'viber'},
    {id: 'instagram', name: 'instagram'},
    {id: 'telegram', name: 'telegram'},
    {id: 'vk', name: 'vk'},
    {id: 'avito', name: 'avito'},
    {id: 'telegroup', name: 'telegroup'},
]

const amoEnabled = [
    {id: 'false', name: 'выкл'},
    {id: 'true', name: 'вкл'},
]

const Dialogues = () => {
    const [dialogues, setDialogues] = useState<IDialog[]>([]);
    
    const [name, setName] = useState<string>('');
    const [chatType, setChatType] = useState<string>('');
    const [amoIsEnabled, setAmoIsAnabled] = useState<string>('');
    const [bitrixIsEnabled, setBitrixIsAnabled] = useState<string>('');
    const [answer_delay, setAnswer_delay] = useState<string>('');
    const [isEditing, setIsEditing] = useState<boolean>(false);

    const [count, setCount] = useState<number>(0);
    const [page, setPage] = useState<number>(1);

    const [phone_number, setPhone_number] = useState<string>('');
    const [lead_info, setLead_info] = useState<string>('');

    const [file, setFile] = useState<any>(null);

    const [searchValue, setSearchValue] = useState<string>('');

    const dispatch = useAppDispatch();

    const navigate = useNavigate();

    const { brandId, channelId } = useParams();

    const handlePhoneChange = (val: string) => {
        const regex = /[a-z]|[A-Z]|[а-я]|[А-Я]/;

        if (!regex.test(val)) {
            setPhone_number(val);
        } else {
            setPhone_number(phone_number);
        }
    };

    const getChannel = () => {
        if (brandId && channelId) {
            ChannelsAPI.getChannel(brandId, channelId)
                .then(response => {
                    if (response.status <= 204) {
                        setName(response.data.name || '');
                        setChatType(response.data.chat_type || '');
                        setAmoIsAnabled(response.data.amo_is_enabled ? 'true' : 'false');
                        setBitrixIsAnabled(response.data.bitrix_is_enabled ? 'true' : 'false')
                        setAnswer_delay(String(response.data.answer_delay));
                    } else {
                        dispatch(notificationActions.setMessage({message: 'Ошибка'}));
                    }
                })
                .catch(() => {
                    dispatch(notificationActions.setMessage({message: 'Ошибка'}));
                })
        }
    }

    const getDialogues = (limit: number, offset: number, search?: string) => {
        if (brandId && channelId) {
            DialoguesAPI.getDialogues(brandId, channelId, limit, offset, search ?? searchValue)
                .then(response => {
                    if (response.status <= 204) {
                        setDialogues(response.data.dialogues);
                        setCount(response.data.amount);
                    } else {
                        dispatch(notificationActions.setMessage({message: 'Ошибка'}));
                    }
                })
                .catch(() => {
                    dispatch(notificationActions.setMessage({message: 'Ошибка'}));
                })
        }
    }

    const handleEdit = (dialogueId: string, isActive: boolean) => {
        if (brandId && channelId) {
            DialoguesAPI.editDialog(brandId, channelId, dialogueId, !isActive)
                .then(response => {
                    if (response.status <= 204) {
                        getDialogues(10, 0);
                        setPage(1);
                        dispatch(notificationActions.setMessage({message: 'Успешно'}));
                    } else {
                        dispatch(notificationActions.setMessage({message: 'Ошибка'}));
                    }
                })
                .catch(() => {
                    dispatch(notificationActions.setMessage({message: 'Ошибка'}));
                })
        }
    }

    const handleClick = (id?: string) => {
        navigate(`dialogues/${id}`);
    }

    const handleSaveDialogue = () => {
        if (brandId && channelId) {
            DialoguesAPI.createDialog(brandId, channelId, phone_number, lead_info)
                .then(response => {
                    if (response.status <= 204) {
                        setPhone_number('');
                        setLead_info('');

                        getDialogues(10, 0);
                        setPage(1);

                        dispatch(notificationActions.setMessage({message: 'Успешно'}));
                    } else {
                        dispatch(notificationActions.setMessage({message: 'Ошибка'}));
                    }
                })
                .catch(() => {
                    dispatch(notificationActions.setMessage({message: 'Ошибка'}));
                })
        }
    }

    const handleSave = () => {
        if (brandId && channelId) {
            ChannelsAPI.editChannel(brandId, channelId, {
                amo_is_enabled: amoIsEnabled === 'true' ? true : false,
                bitrix_is_enabled: bitrixIsEnabled === 'true' ? true : false,
                name,
                answer_delay: Number(answer_delay)
            })
            .then(response => {
                if (response.status <= 204) {
                    getChannel();
                    setIsEditing(false);
                    dispatch(notificationActions.setMessage({message: 'Успешно'}));
                } else {
                    dispatch(notificationActions.setMessage({message: 'Ошибка'}));
                }
            })
            .catch(() => {
                dispatch(notificationActions.setMessage({message: 'Ошибка'}));
            })
        }
    }

    const handleSaveFile = async () => {
        const data = new FormData();

        data.append('file', file);

        if (brandId && channelId) {
            DialoguesAPI.loadFile(brandId, channelId, data)
                .then(response => {
                    if (response.status <= 204) {
                        setFile(null)
                    }else {
                        dispatch(notificationActions.setMessage({message: 'Ошибка'}));
                    }
                })
                .catch(error => {
                    dispatch(notificationActions.setMessage({message: 'Ошибка'}));
                })
        }
    }

    const handleSearch = (value: string) => {
        setSearchValue(value);
        getDialogues(10, 0, value)
    }

    useEffect(() => {
        getDialogues(10, 0);
        getChannel();
    }, []);

    return (
        <div className={styles.body}>
            <div className={styles.inputBlock}>
                <CustomInput
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                    placeholder='Название'
                    disabled={!isEditing}
                />
                <CustomSelect 
                    options={chatTypes}
                    onChangeMethod={setChatType}
                    selectedId={chatType}
                    placeholder='Тип чата'
                    disabled
                />
                <CustomSelect 
                    options={amoEnabled}
                    onChangeMethod={setAmoIsAnabled}
                    selectedId={amoIsEnabled}
                    placeholder='Amo'
                    disabled={!isEditing}
                />
                <CustomSelect 
                    options={amoEnabled}
                    onChangeMethod={setBitrixIsAnabled}
                    selectedId={bitrixIsEnabled}
                    placeholder='Bitrix'
                    disabled={!isEditing}
                />
                <CustomInput
                    value={answer_delay}
                    onChange={(e) => setAnswer_delay(e.target.value)}
                    placeholder='Задержка'
                    type='number'
                    disabled={!isEditing}
                />
                {
                    !!brandId && !isEditing ? (
                        <MyButton 
                            handleClickProps={() => setIsEditing(true)}
                        >
                            Изменить
                        </MyButton>
                    ): (
                        <MyButton 
                            disabled={!name} 
                            handleClickProps={handleSave}
                        >
                            Сохранить
                        </MyButton>
                    )
                }
            </div>
            <div className={styles.rightBlock}>
                <div className={styles.creationBlock}>
                    <h4>Добавить диалог <span className={styles.info}>(работает только с whatsapp и личными аккаунтами telegram)</span></h4>
                    <div className={styles.inputContainer}>
                        <CustomInput
                            value={phone_number}
                            onChange={(e) => handlePhoneChange(e.target.value)}
                            placeholder='Номер телефона'
                        />
                        <CustomInput
                            value={lead_info}
                            onChange={(e) => setLead_info(e.target.value)}
                            placeholder='Описание'
                        />
                        <MyButton 
                            disabled={!phone_number || !lead_info} 
                            handleClickProps={handleSaveDialogue}
                        >
                            Сохранить
                        </MyButton>
                    </div>
                </div>
                <div className={styles.fileBlock}>
                    <h4>Загрузить файл</h4>
                    <div className={styles.inputContainer}>
                        <FilePicker
                            value={file}
                            onChange={setFile}
                        />
                        <MyButton 
                            disabled={!file} 
                            handleClickProps={handleSaveFile}
                        >
                            Загрузить
                        </MyButton>
                    </div>
                </div>
                <div className={styles.searchBlock}>
                    <CustomSearch 
                        onChangeMethod={handleSearch}
                        placeholder='Поиск по никнейму, имени, телефону'
                    />
                </div>
                <div>
                    <table>
                        <thead>
                            <tr>
                                <th>Имя клиента</th>
                                <th>Никнейм клиента</th>
                                <th>Дата и время последнего сообщения</th>
                                <th>Статус чата</th>
                                <th>Ассистент</th>
                                <th>Токены</th>
                                <th>Действия</th>
                            </tr>
                        </thead>
                        <tbody className={styles['']}>
                            {dialogues.map((item) => (
                                <tr key={item.id} onClick={() => handleClick(item.id)} className={styles.cursor}>
                                    <td>
                                        <div className={styles.tdTextName}>{item.contact_name}</div>
                                    </td>
                                    <td>
                                        <div className={styles.tdTextName}>{item.contact_username}</div>
                                    </td>
                                    <td>
                                        <div className={styles.tdText}>{moment(item.last_contact).format('DD-MM-YY hh:mm:ss')}</div>
                                    </td>
                                    <td>
                                        <div className={styles.tdText}>{item.is_active ? 'Активен' : 'Закрыт'}</div>
                                    </td>
                                    <td>
                                        <div className={styles.tdText}>{item.is_active ? 'Включен' : 'Выключен'}</div>
                                    </td>
                                    <td>
                                        <div className={styles.tdText}>{`${item.in_tokens} / ${item.out_tokens}`}</div>
                                    </td>
                                    <td>
                                        <div 
                                            className={`${styles.tdText} ${styles.cursor} ${styles.action}`} 
                                            onClick={(e) => {
                                                e.stopPropagation();
                                                handleEdit(item.id, item.is_active);
                                            }}
                                        >
                                            {item.is_active ? '[Отключить]' : '[Включить]'}
                                        </div>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>

                    <PaginationComponent
                        count={count}
                        limit={10}
                        page={page}
                        setPage={setPage}
                        onChange={getDialogues}
                    />
                </div>
            </div>
        </div>
    )
}

export default Dialogues;
